import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeViewComponent } from './pages/home-view/home-view.component';

import { ForgotPasswordComponent } from '../../_share/components/forgot-password/forgot-password.component';
import { LoginComponent } from '../../_share/pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { CrmManagementComponent } from './pages/crm-management/crm-management.component';
import { PortalManagementComponent } from './pages/portal-management/portal-management.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { TenantManagementComponent } from './pages/tenant-management/tenant-management.component';
import { AuthGuard } from 'src/app/_core';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ServicesComponent } from './pages/services/services.component';
import { OperationsComponent } from './pages/operations/operations.component';
import { IspOperationsComponent } from '../network-portal/pages/isp-operations/isp-operations.component';

const routes: Routes = [
  {
    path: '',
    component: HomeViewComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
        title: 'STEM OM | Home',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsadmin', 'operationsmanager'] },
      },
      {
        path: 'operations',
        component: OperationsComponent,
        pathMatch: 'full',
        title: 'STEM OM | Operations',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsmanager'] },
      },
      {
        path: 'operations/tab/:activeTab',
        component: OperationsComponent,
        title: 'STEM OM | Home',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsmanager'] },
      },
      {
        path: 'isp-operations',
        component: IspOperationsComponent,
        pathMatch: 'full',
        title: 'STEM OM | ISP Operations',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsadmin', 'operationsmanager'] },
      },
      {
        path: 'services',
        component: ServicesComponent,
        pathMatch: 'full',
        title: 'STEM OM | Services',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsadmin', 'operationsmanager'] },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        pathMatch: 'full',
        title: 'STEM OM | Dashboard',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsadmin', 'operationsmanager'] },
      },
      {
        path: 'settings',
        title: 'STEM OM | Settings',
        component: SettingsComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsadmin'] },
      },
      {
        path: 'portal-management',
        title: 'STEM OM | Portal Management',
        component: PortalManagementComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'operationsadmin', 'portaladmin'] },
      },
      {
        path: 'crm-management',
        title: 'STEM OM | CRM Management',
        component: CrmManagementComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'salesmanager'] },
      },
      {
        path: 'tenant-management',
        title: 'STEM | Tenant Management',
        component: TenantManagementComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: { roles: ['superadmin', 'salesmanager'] },
      },
      {
        path: 'forgot-password',
        title: 'STEM OM | Forgot Password',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
      },
      // todo: implement this route
      {
        path: 'login',
        title: 'STEM OM | Login',
        component: LoginComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
