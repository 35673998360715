<div class="flex h-screen w-full">
  <!-- Sidebar for conversations -->
  <!-- <p-sidebar
    [(visible)]="sidebarVisible"
    styleClass="p-sidebar-md"
    [baseZIndex]="10000"
  > -->
  <div class="flex flex-column col-3 w-3">
    <h2 class="text-xl font-bold mb-4">Conversations</h2>
    <ul class="list-none p-0 m-0">
      <li
        *ngFor="let conversation of conversations"
        class="flex align-items-center p-3 border-bottom-1 surface-border cursor-pointer hover:surface-200"
      >
        <p-avatar
          [label]="conversation.name[0]"
          shape="circle"
          class="mr-3"
        ></p-avatar>
        <div>
          <span class="font-bold">{{ conversation.name }}</span>
          <p class="text-sm text-color-secondary m-0">
            {{ conversation.lastMessage }}
          </p>
        </div>
      </li>
    </ul>
    <!-- </p-sidebar> -->
  </div>

  <!-- Main message content area -->
  <div class="flex-1 flex flex-column col-9 w-9">
    <!-- Header -->
    <div
      class="flex align-items-center justify-content-between p-3 surface-section border-bottom-1 surface-border"
    >
      <p-button
        icon="pi pi-bars"
        (click)="sidebarVisible = true"
        styleClass="p-button-text sm:hidden"
      ></p-button>
      <h1 class="text-xl font-bold m-0">Messages</h1>
      <p-button icon="pi pi-cog" styleClass="p-button-text"></p-button>
    </div>

    <!-- Messages area -->
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
      <div class="p-3">
        <div
          *ngFor="let message of messages"
          class="flex mb-2"
          [ngClass]="{ 'justify-content-end': message.sender === 'You' }"
        >
          <div
            class="p-3 bg-blue-500 border-round-lg border-gray-400 border-1 text-white"
            [ngClass]="{ 'bg-gray-400': message.sender === 'You' }"
          >
            <p class="font-bold m-0">
              {{ message.sender }}
            </p>
            <p class="m-0">{{ message.content }}</p>
            <small class="block mt-1 text-color-secondary">{{
              message.timestamp
            }}</small>
          </div>
        </div>
      </div>
    </p-scrollPanel>

    <!-- Message input -->
    <div
      class="p-3 flex align-items-center surface-section border-top-1 surface-border"
    >
      <input
        type="text"
        pInputText
        placeholder="Type a message"
        class="flex-1 mr-3"
      />
      <p-button icon="pi pi-send" styleClass="p-button-rounded"></p-button>
    </div>
  </div>
</div>
