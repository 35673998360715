import { Component, OnInit } from '@angular/core';

interface Conversation {
  id: number;
  name: string;
  lastMessage: string;
}

interface Message {
  id: number;
  sender: string;
  content: string;
  timestamp: string;
}

@Component({
  selector: 'app-conversation-view',
  templateUrl: './conversation-view.component.html',
  styleUrls: ['./conversation-view.component.scss'],
})
export class ConversationViewComponent implements OnInit {
  sidebarVisible: boolean = true;
  conversations: Conversation[] = [
    { id: 1, name: 'John Doe', lastMessage: 'Hey, how are you?' },
    { id: 2, name: 'Jane Smith', lastMessage: 'Can we meet tomorrow?' },
    { id: 3, name: 'Bob Johnson', lastMessage: 'Thanks for your help!' },
  ];
  messages: Message[] = [
    {
      id: 1,
      sender: 'John Doe',
      content: 'Hey, how are you?',
      timestamp: '10:30 AM',
    },
    {
      id: 2,
      sender: 'You',
      content: 'Im doing well, thanks! How about you?',
      timestamp: '10:32 AM',
    },
    {
      id: 3,
      sender: 'John Doe',
      content: 'Great! Just working on some projects.',
      timestamp: '10:35 AM',
    },
    {
      id: 1,
      sender: 'John Doe',
      content: 'Hey, how are you?',
      timestamp: '10:30 AM',
    },
    {
      id: 2,
      sender: 'You',
      content: 'Im doing well, thanks! How about you?',
      timestamp: '10:32 AM',
    },
    {
      id: 3,
      sender: 'John Doe',
      content: 'Great! Just working on some projects.',
      timestamp: '10:35 AM',
    },
  ];
  constructor() {}

  ngOnInit(): void {}
}
