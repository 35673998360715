<div class="grid" style="max-width: 100vw" style="outline: red 1px solid">
  <div class="col-6">
    <p-card header="Order Actions Summary (ISP Specific Orders)" *ngIf="false">
      <p>- Filter orders list on selected order status type</p>
    </p-card>
    <p-card header="ISP Customer Orders List Orders" styleClass="mt-4">
      <p-table
        [value]="orders"
        [paginator]="true"
        [rows]="10"
        responsiveLayout="scroll"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>Order ID</th>
            <th>Status</th>
            <th>Date Created</th>
            <th>Date Modified</th>
            <th>Actions</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td>{{ order.id }}</td>
            <td>{{ order.status }}</td>
            <td>{{ order.dateCreated | date : "short" }}</td>
            <td>
              {{ order.dateModified | date : "short" }}
            </td>
            <td>
              <p-button
                icon="pi pi-pencil"
                styleClass="p-button-rounded p-button-text"
              ></p-button>
              <p-button
                icon="pi pi-trash"
                styleClass="p-button-rounded p-button-text p-button-danger"
              ></p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <p-button label="+ Create New Order" styleClass="mt-4"></p-button>
    </p-card>
  </div>
  <div class="col-6">
    <div class="surface-card p-5 shadow-2">
      <div class="flex flex-column lg:flex-row">
        <div class="flex flex-auto flex-column md:flex-row">
          <div
            class="md:pr-5 lg:pr-8 border-bottom-1 pb-5 md:border-bottom-none md:border-right-1 surface-border flex justify-content-center md:justify-content-start"
          >
            <!-- list of steps for order -->
            <ul class="list-none p-0 m-0">
              <li class="h-6rem flex">
                <div class="flex flex-column align-items-center">
                  <div
                    class="bg-primary border-primary border-circle flex-shrink-0"
                    style="border: 3px solid; height: 1.5rem; width: 1.5rem"
                  ></div>
                  <div class="bg-primary h-6rem" style="width: 3px"></div>
                </div>
                <div class="flex flex-column ml-3">
                  <span class="font-medium text-900 mb-2">Ordered</span>
                  <span class="text-600">22 Feb</span>
                </div>
              </li>
              <li class="h-6rem flex">
                <div class="flex flex-column align-items-center">
                  <div
                    class="surface-card border-primary border-circle flex-shrink-0"
                    style="border: 3px solid; height: 1.5rem; width: 1.5rem"
                  ></div>
                  <div class="surface-300 h-6rem" style="width: 3px"></div>
                </div>
                <div class="flex flex-column ml-3">
                  <span class="font-medium text-900 mb-2">Processing</span>
                  <span class="text-600">23 Feb</span>
                </div>
              </li>
              <li class="h-6rem flex">
                <div class="flex flex-column align-items-center">
                  <div
                    class="surface-300 border-300 border-circle flex-shrink-0"
                    style="border: 3px solid; height: 1.5rem; width: 1.5rem"
                  ></div>
                  <div class="surface-300 h-6rem" style="width: 3px"></div>
                </div>
                <div class="flex flex-column ml-3">
                  <span class="font-medium text-900 mb-2">Shipping</span>
                  <span class="text-600">Est. 24 Feb</span>
                </div>
              </li>
              <li class="h-6rem flex">
                <div class="flex flex-column align-items-center">
                  <div
                    class="surface-300 border-300 border-circle flex-shrink-0"
                    style="border: 3px solid; height: 1.5rem; width: 1.5rem"
                  ></div>
                  <div class="surface-300 h-6rem" style="width: 3px"></div>
                </div>
                <div class="flex flex-column ml-3">
                  <span class="font-medium text-900 mb-2">Delivered</span>
                  <span class="text-600">Est. 26 Feb</span>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="flex-auto md:pl-5 lg:pr-5 pt-5 md:pt-0 border-right-none lg:border-right-1 surface-border"
          >
            <div class="flex align-items-center justify-content-between mb-3">
              <span class="font-medium text-2xl text-900">Product Title</span>
              <button pButton pRipple class="p-button-text font-medium">
                Invoice
              </button>
            </div>
            <div class="text-xl text-700 mb-5">$120.00</div>
            <div class="flex flex-column xl:flex-row mb-5">
              <img
                src="assets/images/blocks/ecommerce/ordersummary/order-summary-3-1.png"
                class="w-12rem xl:mr-5 mb-5 xl:mb-0"
              />
              <img
                src="assets/images/blocks/ecommerce/ordersummary/order-summary-3-2.png"
                class="w-12rem"
              />
            </div>
            <div class="flex flex-column">
              <span class="text-xl font-medium mb-3 text-900">Address</span>
              <div class="flex flex-column text-700">
                <span class="mb-1">Celeste Slater</span>
                <span class="mb-1"
                  >606-3727 Ullamcorper. Roseville NH 11523</span
                >
                <span>(786) 713-8616</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-column border-top-1 lg:border-top-none surface-border pl-0 pt-5 lg:pt-0 lg:pl-5 mt-5 lg:mt-0"
        >
          <button
            pButton
            pRipple
            icon="pi pi-star"
            label="Review"
            class="p-button-outlined mb-3"
          ></button>
          <button
            pButton
            pRipple
            icon="pi pi-times"
            label="Cancel"
            class="p-button-outlined p-button-danger"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12">
    <p-card header="Selected Order Timeline and Details">
      <!-- Add timeline component here when an order is selected -->
      <p>Select an order to view its timeline and details.</p>
      <div class="w-full w-12 flex p-2">
        <!-- select the main area to focus the gmap from this dropdown -->
        <p-dropdown
          [options]="areas"
          optionLabel="name"
          [(ngModel)]="selectedArea"
          (onChange)="onAreaChange($event, areaMap.getMap())"
        ></p-dropdown>
      </div>
      <div>
        <p-gmap
          #areaMap
          [options]="mapOptions"
          [overlays]="overlays"
          [style]="{ width: '100%', height: '320px' }"
        ></p-gmap>
      </div>
    </p-card>
  </div>
</div>
