<div class="w-full w-12 flex p-2">
  <!-- select the main area to focus the gmap from this dropdown -->
  <!-- <p-dropdown
    [options]="areas"
    optionLabel="name"
    [(ngModel)]="selectedArea"
    (onChange)="onAreaChange($event)"
  ></p-dropdown> -->
</div>
<!-- <p-gmap
  #areaMap
  [options]="mapOptions"
  [overlays]="overlays"
  [style]="{ width: '100%', height: '70vh' }"
></p-gmap> -->
<google-map #map [options]="mapOptions" height="70vh" width="100%" [zoom]="12">
  <map-marker
    *ngFor="let overlay of overlays"
    [position]="overlay.position"
    [title]="overlay.title"
    [label]="overlay.label"
    [icon]="overlay.icon"
    (click)="onMarkerClick(overlay)"
  ></map-marker>
  <map-kml-layer
    *ngFor="let kml of kmls"
    [url]="kml.url"
    [options]="kml.options"
  ></map-kml-layer>
</google-map>
